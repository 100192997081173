import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/HomePage.vue')
  },
 
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import('../views/UserCenter.vue')
  },

  {
    path: '/orderList',
    name: 'orderList',
    component: () => import('../views/OrderList.vue')
  },

  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('../views/OrderDetail.vue')
  },


  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/PayOrder.vue')
  },

  {
    path: '/mobileList',
    name: 'mobileList',
    component: () => import('../views/MobileList.vue')
  },
  
  {
    path: '/moneyLog',
    name: 'moneyLog',
    component: () => import('../views/MoneyLog.vue')
  },

  {
    path: '/cash',
    name: 'cash',
    component: () => import('../views/MoneyCash.vue')
  },

  {
    path: '/myTeam',
    name: 'myTeam',
    component: () => import('../views/MyTeam.vue')
  },

  {
    path: '/scene',
    name: 'scene',
    component: () => import('../views/SysScene.vue')
  },

  {
    path: '/cashLog',
    name: 'cashLog',
    component: () => import('../views/CashLog.vue')
  },


  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
