<template>
  <van-config-provider theme="dark">
    <div class="app-main">
      <router-view />
    </div>
    <footer-bar></footer-bar>
  </van-config-provider>
</template>
<script>
import FooterBar from '@/components/FooterBar.vue';
import "vant/lib/index.css"; // 导入 Vant 样式文件
import tp from "tp-js-sdk";
import { onMounted } from 'vue';
import { ConfigProvider } from 'vant';
import "@/assets/fonts/iconfont.css"  //导入图标样式
import "@/assets/fonts/iconfont.js"   //导入图标js样式
export default {

  setup() {
    onMounted(() => {

    });

    return {

    }
  },
  components: {
    FooterBar,
    "van-config-provider": ConfigProvider
  }
}
</script>
<style>
@import '@/assets/css/base.css';


</style>

