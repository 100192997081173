<template>
  <Tabbar v-model="active" route active-color="var(--color-high-text)">
    <TabbarItem replace to="/" icon="home-o">首页</TabbarItem>
    <TabbarItem replace to="/scene" icon="tv-o">场景</TabbarItem>
    <!-- <TabbarItem replace to="/exchange" icon="points">交易</TabbarItem> -->
    <TabbarItem replace to="/userCenter" icon="contact-o">我的</TabbarItem>
  </Tabbar>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Tabbar, TabbarItem } from 'vant';
import { useRoute } from 'vue-router';

const route = useRoute();
const active = ref('');

onMounted(() => {
  active.value = route.path;
});
</script>
<style lang="scss" scoped>
.van-tabbar-item--active{
  color: var(--color-high-text);
}
</style>
